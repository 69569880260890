import { getSnowplowEventPayload, logAnalyticsEvent } from '../analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from '../analytics.const';

const { SITEWIDE_BANNER } = ANALYTICS_CATEGORIES;
const { GOOGLE_ANALYTICS } = ANALYTICS_PROVIDERS;

export const triggerSitewideBannerLoadEvent = (bannerId: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SITEWIDE_BANNER,
		customData: { label: bannerId },
		name: 'banner_displayed',
		schemaName: SNOWPLOW_EVENTS.LOAD.SCHEMA,
		version: SNOWPLOW_EVENTS.LOAD.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `${bannerId} banner displayed`,
			category: SITEWIDE_BANNER,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};

export const triggerSitewideBannerLinkClickEvent = (bannerId: string): void => {
	const eventPayload = getSnowplowEventPayload({
		category: SITEWIDE_BANNER,
		customData: { label: bannerId },
		name: 'banner_feature_click',
		schemaName: SNOWPLOW_EVENTS.CLICK.SCHEMA,
		version: SNOWPLOW_EVENTS.CLICK.VERSION,
	});

	logAnalyticsEvent({ eventPayload });
	logAnalyticsEvent({
		eventPayload: {
			action: `${bannerId} banner link clicked`,
			category: SITEWIDE_BANNER,
			isLegacyEvent: false,
			label: '',
		},
		provider: GOOGLE_ANALYTICS,
	});
};
