import dayjs from 'dayjs';

import { getBannerSettingsValueFromEnv } from 'Components/SitewideBanner/SitewideBanner.helpers';
import { Banner, BannerConfig } from 'Components/SitewideBanner/SitewideBanner.types';
import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';
import useIsAuth from 'Utilities/hooks/useIsAuth';

import { useSitewideBannerConfig } from './useSitewideBannerConfig';

export const useSitewideBanner = (): Banner => {
	const isAuth = useIsAuth();
	const isForceBannerEnabled = useFeatureToggle(FEATURES.forcePromoBanner);
	const isBannerDisabled = useFeatureToggle(FEATURES.disablePromoBanner);
	const bannerConfigs = useSitewideBannerConfig();

	const { endDate, id: chosenId, startDate } = getBannerSettingsValueFromEnv();
	const isWithinTimeRange = startDate && endDate && dayjs().tz('Europe/London').isBetween(startDate, endDate);

	if (isBannerDisabled || !isAuth) {
		return { shouldRenderBanner: false };
	}

	let priorityBannerConfig: BannerConfig | null = null;
	let maxPriorityIndex = 0;

	for (const config of bannerConfigs) {
		// Not a priority banner, skip
		if (!config.priorityIndex) {
			continue;
		}

		// Banner is disabled, skip
		if (!config.isEnabled) {
			continue;
		}

		// If ID doesn't match env banner ID (when shouldExpire is true), skip
		if (config.shouldExpire && config.id !== chosenId) {
			continue;
		}

		// Find the highest priority banner
		if (config.priorityIndex > maxPriorityIndex) {
			// If the priority banner should expire and its show time has passed, and the force banner is not enabled, skip
			if (config.shouldExpire && !isWithinTimeRange && !isForceBannerEnabled) {
				continue;
			}

			// banner has higher priority, update the max priority banner
			maxPriorityIndex = config.priorityIndex;
			priorityBannerConfig = config;
		}
	}

	// If a priority banner is found, render it
	if (priorityBannerConfig) {
		return { config: priorityBannerConfig, shouldRenderBanner: true };
	}

	const config = bannerConfigs.find(({ id }) => id === chosenId);
	const shouldRenderBanner = isForceBannerEnabled || (isWithinTimeRange && config?.isEnabled);

	return { config, shouldRenderBanner };
};
