export default {
	deliveryTimeline: {
		linkLabel: 'purchases',
		text: 'Use Motorway Move, and get detailed delivery updates so you know when to expect your purchases. Just click ‘delivery details’ in your',
		title: 'Track your deliveries',
	},
	kycCTA: {
		linkLabel: 'complete and submit your company information',
		text: 'To have access to all sections of the platform, ',
		title: 'Submit company information',
	},
};
