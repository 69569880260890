import { throttle } from 'lodash';
import { useRouter } from 'next/router';

import { checkIsVehicleListPage } from 'Components/VehicleList/VehicleList.helpers';
import { SELLER_TYPES, VEHICLE_LIST_FILTER_NAME } from 'Components/VehicleList/VehicleListFilters.consts';
import { triggerSitewideBannerLinkClickEvent } from 'Services/analytics/events/sitewideBannerEvents';
import { resolveVehicleListParams } from 'Services/services.helpers';
import { LIST_TYPES } from 'Utilities/consts';
import { isCurrentPath } from 'Utilities/helpers/isCurrentPath';
import ROUTES from 'Utilities/routes';

import { BannerConfig } from '../SitewideBanner.types';

const { LIST_TYPE, SELLER_TYPE } = VEHICLE_LIST_FILTER_NAME;
const ON_LINK_CLICK_THROTTLE_TIME = 1000;

const id = 'tradeVehiclesIntroPromoV2';
const isEnabled = true;

const badge: BannerConfig['badge'] = {
	text: 'NEW!',
	variant: 'primary',
};

const texts = {
	linkLabel: 'See trade vehicles in your Marketplace',
	text: 'Buy high-quality trade vehicles from trusted and verified dealers.',
	title: 'Buy and sell trade vehicles',
};

const query = resolveVehicleListParams({ [LIST_TYPE]: LIST_TYPES.auction, [SELLER_TYPE]: SELLER_TYPES.TRADE });
const route = { as: ROUTES.VEHICLE_LIST.as() + query, href: ROUTES.VEHICLE_LIST.href + query };

export const useTradeVehiclesBannerConfig = (): BannerConfig => {
	const router = useRouter();

	const onLinkClick = throttle(async (): Promise<void> => {
		const { as, href } = route;
		const path = as || href;

		triggerSitewideBannerLinkClickEvent(id);

		if (isCurrentPath(path) || checkIsVehicleListPage()) {
			// If the link is the same as the current path, we need to request a new p age as internal routing causes an error.
			// Also, there is currently an issue when shallow routing to vehicle filter lists from the marketplace.
			window.location.href = path;
			return;
		}

		await router.push(href, as, { shallow: true });
	}, ON_LINK_CLICK_THROTTLE_TIME);

	return {
		badge,
		id,
		isEnabled,
		linkProps: { onClick: onLinkClick },
		texts,
	};
};
