import { MDText } from 'i18n-react';

import ROUTES from 'Utilities/routes';

import LocalTexts from './AccountSettings.texts';

const LocalT = new MDText(LocalTexts);

export enum AccountSettingsSlugs {
	ACCOUNT_REPORTING = 'reporting',
	COMPANY_INFORMATION = 'company-information',
	HOME = '',
}

export type MetaDataKeyPage = 'homepage' | 'companyInformation';

export const ACCOUNT_SETTINGS_COMPANY_INFORMATION_LINK = ROUTES.ACCOUNT_SETTINGS.as(
	AccountSettingsSlugs.COMPANY_INFORMATION,
);

interface NavigationSection {
	href: string;
	id: string;
	text: string;
}

export const ACCOUNT_REPORTING_LINK = ROUTES.ACCOUNT_SETTINGS.as(AccountSettingsSlugs.ACCOUNT_REPORTING);

export const ACCOUNT_SETTINGS_LINK_TITLE = `${LocalT.translate('titles.accountSettings')}`;
export const COMPANY_INFORMATION_LINK_TEXT = `${LocalT.translate('links.companyInformation')}`;
export const ACCOUNT_REPORTING_LINK_TEXT = `${LocalT.translate('links.accountReporting')}`;
export const HOME_LINK_TEXT = `${LocalT.translate('links.home')}`;

export const ACCOUNT_SETTINGS_COMPANY_INFORMATION_SECTION: NavigationSection = {
	href: ACCOUNT_SETTINGS_COMPANY_INFORMATION_LINK,
	id: 'companyInformation',
	text: COMPANY_INFORMATION_LINK_TEXT,
};

export const ACCOUNT_SETTINGS_ACCOUNT_REPORTING_SECTION: NavigationSection = {
	href: ACCOUNT_REPORTING_LINK,
	id: 'accountReporting',
	text: ACCOUNT_REPORTING_LINK_TEXT,
};
