import React, { useEffect } from 'react';

import { Banner } from 'Components/Banner/Banner';
import { triggerSitewideBannerLoadEvent } from 'Services/analytics/events/sitewideBannerEvents';

import { useSitewideBanner } from './hooks/useSitewideBanner';

const SitewideBanner: React.FC = () => {
	const banner = useSitewideBanner();
	const { config, shouldRenderBanner } = banner;

	useEffect(() => {
		if (shouldRenderBanner && config?.id) {
			triggerSitewideBannerLoadEvent(config?.id);
		}
	}, [config?.id, shouldRenderBanner]);

	if (!shouldRenderBanner || !config?.id) {
		return null;
	}

	const { badge, id, isDismissible, linkProps, texts } = config;

	return (
		<Banner
			key="SitewideBanner"
			badge={badge}
			id={id}
			isDismissible={isDismissible}
			linkProps={linkProps}
			texts={texts}
		/>
	);
};

export default SitewideBanner;
