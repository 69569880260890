type GetConfigFromString = (inputString: string, delimiter?: string) => Record<string, string | undefined>[];

export const getConfigFromString: GetConfigFromString = (inputString, delimiter = ';') => {
	if (!inputString) {
		return [];
	}

	return inputString.split(delimiter).map((collection) =>
		collection.split('&').reduce((acc, pair) => {
			const [key, value] = pair.split('=');
			const newValue = key ? { [key]: value } : {};
			return { ...acc, ...newValue };
		}, {}),
	);
};
