import useFeatureToggle, { FEATURES } from 'Utilities/hooks/useFeatureToggle';

import { BannerConfig } from '../SitewideBanner.types';

const id = 'paymentsKYC';
const badge = { text: 'Reminder' };
const texts = {
	text: `Please submit all your required information as soon as possible to ensure there's no delay in getting started with Motorway Payments.`,
	title: 'Your KYC application is incomplete',
};
const isDismissible = false;

export const usePaymentsKYCBannerConfig = (): BannerConfig => {
	const isPaymentsKYCBannerDisplayed = useFeatureToggle(FEATURES.paymentsKYC);
	const isEnabled = isPaymentsKYCBannerDisplayed;

	return { badge, id, isDismissible, isEnabled, priorityIndex: 99999, texts };
};
