import { isTestEnv } from 'Utilities/helpers';

import { BannerConfig } from '../SitewideBanner.types';

import { useDeliveryTimelineConfig } from './useDeliveryTimelineBannerConfig';
import { useKycBannerConfig } from './useKycBannerConfig';
import { useMotorwayMoveBannerConfig } from './useMotorwayMoveBannerConfig';
import { useMotorwayPaymentAnniversaryBannerConfig } from './useMotorwayPaymentAnniversaryBannerConfig';
import { usePaymentsKYCBannerConfig } from './usePaymentsKYCBannerConfig';
import { useTradeVehiclesBannerConfig } from './useTradeVehiclesBannerConfig';

export const useSitewideBannerConfig = (): BannerConfig[] => {
	const tradeVehiclesBannerConfig = useTradeVehiclesBannerConfig();
	const paymentsKYCBannerConfig = usePaymentsKYCBannerConfig();
	const collectAppBannerConfig = useMotorwayMoveBannerConfig();
	const deliveryTimelineConfig = useDeliveryTimelineConfig();
	const motorwayPaymentAnniversaryBannerConfig = useMotorwayPaymentAnniversaryBannerConfig();
	const kycBannerConfig = useKycBannerConfig();

	const banners = [
		paymentsKYCBannerConfig,
		tradeVehiclesBannerConfig,
		collectAppBannerConfig,
		deliveryTimelineConfig,
		motorwayPaymentAnniversaryBannerConfig,
		kycBannerConfig,
	];

	if (isTestEnv) {
		return banners.filter(({ ignoreInTests }) => ignoreInTests !== true);
	}

	return banners;
};
