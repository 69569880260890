export default {
	links: {
		accountReporting: 'Account reporting',
		companyInformation: 'Company information',
		home: 'Home',
	},
	titles: {
		accountSettings: 'Account',
	},
};
