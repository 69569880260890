import getConfig from 'next/config';

import { logger } from 'Services/logger/logger';
import { getConfigFromString } from 'Utilities/helpers/getConfigFromString';

import { BannerSettingsFromEnv } from './SitewideBanner.types';

const { publicRuntimeConfig } = getConfig();
const { SITEWIDE_BANNER_SETTINGS } = publicRuntimeConfig;

export const getBannerSettingsValueFromEnv = (): BannerSettingsFromEnv => {
	try {
		const [settings = {}] = SITEWIDE_BANNER_SETTINGS ? getConfigFromString(SITEWIDE_BANNER_SETTINGS) : [];
		const { endDate, id, startDate } = settings;
		return { endDate, id, startDate };
	} catch (error) {
		logger.error({
			error: error as Error,
			message: 'Error to get banner settings value from env',
			scope: 'getBannerSettingsValueFromEnv',
		});
		return {};
	}
};
