import { GiftIcon } from '@motorway/mw-highway-code';

import ROUTES from 'Utilities/routes';

import { BannerConfig } from '../SitewideBanner.types';

const id = 'motorwayPay1Anniversary';
const isDismissible = true;
const isEnabled = true;
const priorityIndex = 1000;
const shouldExpire = true;
const ignoreInTests = true;

const badge: BannerConfig['badge'] = {
	icon: GiftIcon,
	reverse: true,
	text: 'NEW!',
	variant: 'primary',
};

const texts: BannerConfig['texts'] = {
	linkLabel: 'Find out more about Motorway Pay.',
	text: 'From streamlined purchasing, to instantly withdrawing funds, your transactions keep getting easier.',
	title: '1 year of Motorway Pay – what’s new?',
};

const linkProps: BannerConfig['linkProps'] = {
	href: ROUTES.MOTORWAY_PAY.as(),
	target: '_blank',
};

export const useMotorwayPaymentAnniversaryBannerConfig = (): BannerConfig => {
	return {
		badge,
		id,
		ignoreInTests,
		isDismissible,
		isEnabled,
		linkProps,
		priorityIndex,
		shouldExpire,
		texts,
	};
};
