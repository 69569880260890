import { MDText } from 'i18n-react';

import { AccountCategory, AccountSlugs } from 'Types/account';
import ROUTES from 'Utilities/routes';

import LocalTexts from '../SitewideBanner.texts';
import { BannerConfig } from '../SitewideBanner.types';

export const useDeliveryTimelineConfig = (): BannerConfig => {
	const localT = new MDText(LocalTexts.deliveryTimeline);

	const id = 'deliveryTimeline';
	const isDismissible = true;
	const isEnabled = true;
	const priorityIndex = 1001;
	const shouldExpire = true;
	const ignoreInTests = true;

	const badge: BannerConfig['badge'] = {
		text: 'NEW!',
		variant: 'default',
	};

	const texts: BannerConfig['texts'] = {
		linkLabel: `${localT.translate('linkLabel')}`,
		text: `${localT.translate('text')}`,
		title: `${localT.translate('title')}`,
	};

	const linkProps: BannerConfig['linkProps'] = {
		href: ROUTES.ACCOUNT.as(AccountCategory.PURCHASES, AccountSlugs.COMPLETE),
	};

	return {
		badge,
		id,
		ignoreInTests,
		isDismissible,
		isEnabled,
		linkProps,
		priorityIndex,
		shouldExpire,
		texts,
	};
};
